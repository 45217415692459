import styled from 'styled-components';
import { Colors } from './theme';

function App() {
  return (
    <>
      <Background>
        <section>
          <BannerLayout>
            <BannerImg src='header-fan.png' alt='fan' />
          </BannerLayout>
          <Header>FANS</Header>
          <MoreFans>
            <Img src='fan2.jpeg' alt='fan' />
            <Img src='fan4.webp' alt='fan' />
            <Img src='fan3.jpeg' alt='fan' />
          </MoreFans>
          <ButtonLayout>
            <Button>I'm a fan!</Button>
            <Button>I'm a fan!</Button>
          </ButtonLayout>
          <FanLayout>
            <Img src='fan15.jpeg' alt='fan' />
            <Img src='fan6.jpeg' alt='fan' />
          </FanLayout>
          <MoreFans>
            <Img src='fan5.jpeg' alt='fan' />
            <Img src='fan7.webp' alt='fan' />
            <Img src='fan9.jpeg' alt='fan' />
          </MoreFans>
          <ButtonLayout>
            <Button>I'm a fan!</Button>
            <Img src='fan8.jpeg' alt='fan' />
          </ButtonLayout>
          <FanLayout>
            <Img src='fan10.jpeg' alt='fan' />
          </FanLayout>
          <MoreFans>
            <Img src='fan11.png' alt='fan' />
            <Img src='fan12.webp' alt='fan' />
            <Img src='fan13.jpeg' alt='fan' />
          </MoreFans>
          <ButtonLayout>
            <Button>I'm a fan!</Button>
            <Button>I'm a fan!</Button>
          </ButtonLayout>
          <FanLayout>
            <Img src='fan14.jpeg' alt='fan' />
            <Img src='fan1.jpeg' alt='fan' />
          </FanLayout>
        </section>
      </Background>
    </>
  );
}

const Background = styled.section`
  min-height: 100%;
  min-width: 100%;
`;

const Header = styled.h1`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.purple_x_11};
  color: ${Colors.beige};
  height: 4rem;
  align-items: center;
  margin: 1rem 0;
`;

const ButtonLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: ${Colors.aquamarine};
  height: 10rem;
  outline: none;
  border: 1px solid ${Colors.beige};
  border-radius: 5px;

  &:nth-child(2) {
    background-color: ${Colors.cotton_candy};
  }
`;

const FanLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  max-width: 50vw;
  margin: 1rem;
`;

const BannerLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
`;

const BannerImg = styled.img`
  max-width: 100%;
  max-height: 80vh;
  filter: opacity(70%) hue-rotate(-30deg) blur(0.5px);
`;

const MoreFans = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100vw;
`;

export default App;
